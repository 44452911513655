<template>
  <div class="inline-table">
    <v-row v-if="!hideFilters">
      <v-col cols="11">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <div class="text-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" class="mt-4" icon v-bind="attrs" v-on="on">
                <v-icon> mdi-filter-variant </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <template v-for="(header, i) in headers">
                  <v-list-item
                    :key="i"
                    v-if="
                      !header.noFilterable && (header.disabled || !header.input)
                    "
                  >
                    <v-list-item-action class="mr-0">
                      <v-autocomplete
                        :items="filtersEls"
                        v-model="filters[header.value]"
                        :item-text="header.value"
                        :item-value="header.value"
                        class="filter-autocomplete-table"
                        multiple
                        chips
                        clearable
                        solo
                        deletable-chips
                        small-chips
                        :label="header.text"
                      >
                      </v-autocomplete>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="filters = []">
                  Clear Filters
                </v-btn>
                <v-btn text @click="menu = false"> Cancel </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="success"
    ></v-progress-linear>
    <v-data-table
      :height="height || '53vh'"
      fixed-header
      :disable-pagination="true"
      :hide-default-footer="true"
      :dense="true"
      :headers="preparedHeaders"
      :items="els"
      :search="search"
       multi-sort
      :single-select="singleSelect"
      :show-select="showCheckbox"
      :custom-filter="filterText"
      ref="inlineTable"
      :key="tableKey"
      v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
    >
      <template
        v-if="showCheckbox"
        v-slot:header.data-table-select="{ on, props }"
      >
        <v-checkbox
          v-model="checkAll"
          :indeterminate="indeterminate"
          @change="selectAll($event, props)"
        ></v-checkbox>
      </template>
      <template slot="item" slot-scope="props">
        <tr
          :key="props.index"
          :class="{ 'table-row': blinkRow === props.index }"
          :ready="setBlink(props)"
          @click="rowClick(props.item)"
        >
          <td class="table-col" v-if="showCheckbox">
            <v-checkbox
              v-model="selectedChangeBoxes[props.item.checkbox_id]"
              @change="checkboxChange()"
              :key="tableKey"
            ></v-checkbox>
          </td>

          <td
            :width="getWidth()"
            @click="onColumnClick(props.item, header)"
            class="text-center align-middle table-col"
            v-show="header.align !== ' d-none'"
            v-for="(header, i) in headers"
            :key="i"
          >
            <span
              v-if="!i && props.item.isModified"
              class="mdi mdi-checkbox-multiple-marked-circle is-modified"
            ></span>
            <input
              v-if="header.input"
              class="p-2 text-center col-12"
              v-model="props.item[header.value]"
              :disabled="isDisabled(header)"
              @focus="setFocus(props.item)"
              @change="onChange(props.item)"
            />
            <template v-for="(button, i) in header.buttons || []">
              <button
                :key="i"
                type="button"
                :class="'ml-2 button-' + button"
                @click="$emit(button.action, props.item)"
              >
                <i
                  :style="'color:' + button.color"
                  :class="'fa fa-' + button.icon"
                ></i>
              </button>
            </template>
            <slot
              v-if="header.slot || header.combo"
              v-bind="{ header: header, item: props.item }"
              name="select"
            >
            </slot>
            <v-tooltip v-if="header.hover" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="set-cursor-pointer color-link"
                  v-bind="attrs"
                  v-on="on"
                  @click="addClick(header.click, props.item)"
                  >{{ props.item[header.value] }}</span
                >
              </template>
              <span
                class="set-cursor-pointer"
                v-html="getContent(header.hover, props.item)"
              ></span>
            </v-tooltip>
            <span
              v-if="
                !header.input &&
                !header.buttons &&
                !header.combo &&
                !header.hover &&
                !header.slot
              "
            >
              {{ props.item[header.value] }}
            </span>
          </td>
          <template
            v-if="
              !notSpecial &&
              Object.entries(focusElement).toString() ===
                Object.entries(props.item).toString()
            "
          >
            <button type="button" class="button-save" @click="store()">
              <i class="fa fa-check-circle"></i>
            </button>
            <button type="button" class="button-back" @click="undo()">
              <i class="fa fa-undo"></i>
            </button>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import Sortable from "sortablejs";

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const currentClassState = mutation.target.classList.contains(
          classToWatch
        );
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

export default {
  props: [
    "items",
    "headers",
    "loading",
    "notSpecial",
    "showCheckbox",
    "hideFilters",
    "height",
    "selected",
    "getContent",
    "sort",
    "tableName",
  ],
  data() {
    return {
      singleSelect: false,
      search: "",
      focusElement: {},
      selectedItem: {},
      beforeEdit: {},
      beforeEditId: -1,
      els: [],
      filtersEls: [],
      drawer: false,
      group: [],
      filters: {},
      fav: true,
      menu: false,
      message: false,
      hints: true,
      selectedChangeBoxes: {},
      checkAll: false,
      tableKey: 0,
      indeterminate: false,
      blinkRow: null,
      sortables:[]
    };
  },
  watch: {
    items: function (newValue) {
      let ov = this.headers.find((x) => !x.disabled).value;
      this.selectedChangeBoxes = this.selected ? this.selected : {};

      this.els = newValue.map((x, i) => {
        x.original_val = x[ov];
        x.checkbox_id = i;
        this.selectedChangeBoxes[x.checkbox_id] = this.selected
          ? this.selected[x.checkbox_id]
          : true;
        return x;
      });
      this.getEls(this.els);
      this.checkboxChange(true);
    },
  },
  computed: {
    preparedHeaders() {
      if (this.headers.filter((x) => x.value === "original_val").length > 0) {
        return this.headers;
      }
      if (this.notSpecial) {
        this.headers.push({
          align: " d-none",
          disabled: false,
          input: true,
          text: this.headers.find((x) => !x.disabled).text,
          value: "original_val",
        });
      } else {
        this.headers.push({
          align: " d-none",
          disabled: true,
          input: false,
          text: this.headers.find((x) => !x.disabled).text,
          value: "original_val",
        });
      }

      this.headers.map((x) => {
        if (x.disabled || !x.input) {
          x["filter"] = (value) => {
            if (!this.filters || !this.filters[x.value]) {
              return true;
            }
            if (this.filters[x.value].length === 0) {
              return true;
            }
            return this.filters[x.value].indexOf(value) !== -1;
          };
        }
      });
      this.getEls(this.els);
      return this.headers;
    },
  },
  methods: {
    isDisabled(column) {
      return column.disabled;
    },
    setFocus(item) {
      this.focusElement = item;
      this.selectedItem = { ...item };
    },
    onChange(item) {
      this.beforeEdit = { ...this.selectedItem };
      this.beforeEditId = this.items.indexOf(this.focusElement);
      this.$emit("changeEvent", item);
    },
    store() {
      this.$emit("changeInput", this.els);
    },
    undo() {
      if (this.beforeEditId > -1) {
        this.items[this.beforeEditId] = this.beforeEdit;
        this.els = [];
        this.$nextTick(() => {
          this.els = this.items;
        });
      }

      this.$emit("changeInput", this.items);
    },
    getWidth() {
      return (
        100 / this.headers.filter((x) => x.align !== " d-none").length + "%"
      );
    },
    filterText(value, search, item) {
      let exists = false;
      if (value === null || search === null) {
        return exists;
      }

      this.headers.some((x) => {
        if (x.noFilterable) {
          return true;
        }
        let val = item[x.value];
        if (
          typeof val === "string" &&
          val.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        ) {
          exists = true;
          return true;
        }
      });
      return exists;
    },
    rowClick(item) {
      this.$emit("rowClick", item);
    },
    checkboxChange(isInitial) {
      let checkedLength = Object.keys(this.selectedChangeBoxes).filter(
        (x) => this.selectedChangeBoxes[x] === true
      ).length;
      if (checkedLength === this.els.length) {
        this.checkAll = true;
        this.indeterminate = false;
      } else if (checkedLength === 0) {
        this.checkAll = false;
        this.indeterminate = false;
      } else {
        this.checkAll = false;
        this.indeterminate = true;
      }
      this.$emit("checkboxes", { selectedChangeBoxes: this.selectedChangeBoxes, isInitial: isInitial });
    },
    onColumnClick(item, column) {
      if (column.onClick) {
        this.$emit(column.onClick, item);
      }
    },
    selectAll: function (checked, props) {
      this.els.forEach((x) => {
        this.selectedChangeBoxes[x.checkbox_id] = checked;
      });
      this.tableKey++;
      this.$emit("checkboxes", { selectedChangeBoxes: this.selectedChangeBoxes, isInitial: false });
    },
    setBlink(props) {
      if (!props.item.lastEdited) {
        return false;
      }
      this.blinkRow = props.index;
      props.item.lastEdited = false;
      setTimeout(() => {
        this.blinkRow = null;
      }, 3000);
      return true;
    },
    addClick(event, item) {
      if (event) {
        this.$emit(event, item);
      }
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      headersTmp.splice(
        evt.newIndex - this.sort,
        0,
        headersTmp.splice(evt.oldIndex - this.sort, 1)[0]
      );
      this.headers = headersTmp;
      window.localStorage.setItem(this.tableName, JSON.stringify(this.headers));
      console.log(this.tableName);

      this.tableKey++;
    },
    getEls(els) {
      let filters = JSON.parse(JSON.stringify(this.headers)).filter(
        (x) => !x.noFilterable
      );
      this.filtersEls = JSON.parse(JSON.stringify(els)).map((x) => {
            filters.forEach((f) =>{
                    x[f.value] ? x[f.value] : (x[f.value] = "No Value")
            }
        );
        return x;
      });
    },
  },
  directives: {
    "sortable-table": {
      inserted: (el, binding, vnode) => {
        if (vnode.context.sort) {
          el.querySelectorAll("th").forEach((draggableEl) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            watchClass(draggableEl, "sortHandle");
            draggableEl.classList.add("sortHandle");
          });
          Sortable.create(
            el.querySelector("tr"),
            binding.value ? { ...binding.value, handle: ".sortHandle" } : {}
          );
        } else {
          return true;
        }
      },
    },
  },
};
</script>

<style scoped>
.is-modified {
  position: absolute;
  left: 4px;
  top: 4px;
  color: green;
  font-size: 16px;
}
.table-col {
  position: relative;
}

.table-row {
  animation: blinker 0.7s linear infinite;
}

@keyframes blinker {
  50% {
    background: #00ca19;
  }
}
</style>