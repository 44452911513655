<template>
  <div>
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ getTitle() }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <v-form
                    @submit.prevent
                    ref="requestData"
                    v-model="validRequestData"
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="requestData.name"
                          label="Name"
                          :rules="[validations.required()]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="requestData.type_id"
                          label="Type"
                          :items="types"
                          item-text="name"
                          item-value="id"
                          disabled
                          :rules="[validations.required()]"
                          @change="
                            onTypeChange();
                            getTags();
                          "
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="requestData.normalize_type_id"
                          label="Type"
                          :items="normalizeTypes"
                          item-text="name"
                          item-value="id"
                          :rules="[validations.required()]"
                          @change="onTypeChange()"
                        ></v-select>
                      </v-col>
                      <template
                        v-if="
                          requestData.normalize_type_id === 1 ||
                          requestData.normalize_type_id === 4
                        "
                      >
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="requestData.elements.mapping_id"
                            label="Mapping"
                            :items="mappings"
                            item-text="name"
                            item-value="id"
                            placeholder="Select"
                            :rules="[validations.required()]"
                            @change="getMapping()"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="requestData.elements.target_id"
                            label="Tag"
                            :items="tags"
                            item-text="name"
                            item-value="id"
                            :rules="[validations.required()]"
                            placeholder="Select"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                          v-if="requestData.normalize_type_id === 4"
                            v-model="requestData.elements.base"
                            label="Base"
                            :items="requestData.details"
                            item-text="key"
                            item-value="key"
                            :rules="[validations.required()]"
                            placeholder="Select"
                          ></v-select>
                        </v-col>
                      </template>

                      <template v-if="requestData.normalize_type_id === 2">
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="requestData.elements.column"
                            label="Column"
                            :items="dataFormatColumns"
                            item-text="name"
                            item-value="id"
                            placeholder="Select"
                            :rules="[validations.required()]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model="requestData.elements.format"
                            label="Format"
                            :items="dataFormats"
                            item-text="name"
                            item-value="id"
                            :rules="[validations.required()]"
                            placeholder="Select"
                          ></v-select>
                        </v-col>
                      </template>
                    </v-row>

                    <template v-if="requestData.normalize_type_id === 3">
                      <v-row>
                        <v-col cols="12">
                          <v-alert dense type="info">
                            In column "value" you can use special word and
                            combination: <strong>"today"</strong> (this means
                            current date). <strong>"today(+|-)N"</strong> ("N"
                            is number of days) for example "today+7".
                          </v-alert>
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table :key="tableKey">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center">Type</th>
                                  <th class="text-center">Column</th>
                                  <th class="text-center">Operator</th>
                                  <th class="text-center">Value</th>
                                  <th class="text-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(element, i) in requestData.elements
                                    .where"
                                  :key="i"
                                >
                                  <td>
                                    <v-select
                                      v-if="i > 0"
                                      v-model="element.type"
                                      :items="expresionTypes"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[validations.required()]"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-select
                                      v-model="element.column"
                                      :items="expresionColumns"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[validations.required()]"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-select
                                      v-model="element.operator"
                                      :items="operators"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[validations.required()]"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="element.value"
                                    ></v-text-field>
                                  </td>
                                  <td class="pt-3 text-center">
                                    <v-btn color="info" v-on:click="addRow()"
                                      >+</v-btn
                                    >
                                    <v-btn
                                      color="warning"
                                      v-if="
                                        requestData.elements.where.length > 1
                                      "
                                      class="ml-4"
                                      v-on:click="removeRow(i)"
                                      >-</v-btn
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </template>

                    <v-row
                      v-if="
                        requestData.normalize_type_id === 1 ||
                        (id && requestData.normalize_type_id === 4)
                      "
                    >
                      <v-col cols="12" offset="1" sm="10">
                        <Table
                          :loading="isProcessing"
                          :headers="headers"
                          :items="requestData.details"
                          @changeInput="change"
                        ></Table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
                  <v-btn color="primary" v-on:click="store()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { GET_MAPPINGS, GET_MAPPING_GROUPED } from "@/store/mappings.module";
import {
  GET_NORMALIZE_DATA_TYPES,
  UPDATE_NORMALIZE_DATA,
  CREATE_NORMALIZE_DATA,
  GET_NORMALIZE_DATA,
} from "@/store/normalize_data.module";
import { SHOW_SNACK } from "@/store/snack.module";
import { GET_TAG_CATEGORY_USES } from "@/store/tagCategoryUse.module";
import { GET_ONLY_SELECT_CATEGORIES } from "@/store/tagCategory.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import Table from "@/views/partials/inlineEditTable/Table.vue";
import validations from "@/views/partials/form/Validations.vue";

export default {
  components: { KTPortlet, TPConfirm, Table },
  name: "StoreNormalizeData",
  data() {
    return {
      id: null,
      validations: validations,
      tab: "tab-1",
      isProcessing: false,
      requestData: { details: [], elements: {}, type_id: 1 },
      validRequestData: false,
      normalizeTypes: [],
      types: [],
      tags: [],
      mappings: [],
      tableKey: 0,
      headers: [],
      dataFormatColumns: [
        { id: "date", name: "Date" },
        { id: "expire_date", name: "Expire Date" },
      ],
      dataFormats: [
        { id: "Y-W", name: "Week" },
        { id: "Y.m.d", name: "YYYY.MM.DD" },
        { id: "d.m.Y", name: "DD.MM.YYYY" },
      ],
      expresionColumns: [
        { id: "date", name: "Date" },
        { id: "expire_date", name: "Expire Date" },
      ],
      expresionTypes: [
        { id: "AND", name: "AND" },
        { id: "OR", name: "OR" },
      ],
      operators: [
        { id: "=", name: "=" },
        { id: "!=", name: "!=" },
        { id: ">", name: ">" },
        { id: "<", name: "<" },
        { id: ">=", name: ">=" },
        { id: "<=", name: "<=" },
        { id: "IN", name: "IN" },
        { id: "LIKE", name: "LIKE" },
        { id: "IS NULL", name: "IS NULL" },
        { id: "IS NOT NULL", name: "IS NOT NULL" },
      ],
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.selectNormalizeData();
    this.getNormalizeTypes();
    this.getTypes();
  },
  methods: {
    selectNormalizeData() {
      if (!this.id) {
        this.initTableHeaders();
        return;
      }

      this.isProcessing = true;
      this.$store
        .dispatch(GET_NORMALIZE_DATA, this.id)
        .then((response) => {
          this.requestData = response;
          if (
            !this.requestData.elements ||
            typeof this.requestData.elements !== "object"
          ) {
            this.requestData.elements = {};
          }

          this.initTableHeaders();

          this.getMappings(true);
          let details = this.requestData.details;
          this.requestData.details = [];
          this.$nextTick(() => {
            this.requestData.details = details;
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.isProcessing = false;
        });
    },

    getNormalizeTypes() {
      this.$store.dispatch(GET_NORMALIZE_DATA_TYPES).then((response) => {
        this.normalizeTypes = response;
      });
    },

    getTypes() {
      this.$store.dispatch(GET_TAG_CATEGORY_USES).then((response) => {
        this.types = response.data;
        this.getTags();
      });
    },

    initTableHeaders() {
        let hasInput = this.requestData.normalize_type_id !== 4;

        this.headers = [
            {
            text: "Key",
            value: "key",
            align: "center",
            input: hasInput,
            disabled: true,
            },
            {
            text: "Value",
            value: "value",
            align: "center",
            input: hasInput,
            disabled: false,
            }
        ];
    },

    getMappings() {
      if (this.requestData.normalize_type_id !== 1 && this.requestData.normalize_type_id !== 4) {
        if (this.requestData.normalize_type_id === 3) {
          if (
            this.requestData.elements.where &&
            this.requestData.elements.where.length > 0
          ) {
          } else {
            this.addRow();
          }
        }
        return;
      }
      this.$store
        .dispatch(GET_MAPPINGS, this.requestData.type_id)
        .then((response) => {
          this.mappings = response;
          this.mappings.unshift({ id: "", name: "Select" });
        });
    },
    getMapping() {
      if (!this.requestData.elements.mapping_id || !this.requestData.type_id) {
        return;
      }
      this.$store
        .dispatch(GET_MAPPING_GROUPED, [
          this.requestData.elements.mapping_id,
          this.requestData.type_id,
        ])
        .then((response) => {
          this.requestData.details = response.map((x) => ({
            key: x,
            value: "",
          }));
          console.log(this.requestData.details);
        });
    },
    getTags() {
      this.$store
        .dispatch(GET_ONLY_SELECT_CATEGORIES, {
          type: this.requestData.type_id,
        })
        .then((response) => {
          this.tags = response;
        });
    },
    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

      this.isProcessing = true;

      let url = CREATE_NORMALIZE_DATA;
      let params = this.requestData;
      if (this.id) {
        url = UPDATE_NORMALIZE_DATA;
        params = {
          id: this.id,
          params: this.requestData,
        };
      }

      this.$store
        .dispatch(url, params)
        .then((resource) => {
          if (this.requestData.normalize_type_id === 4) {
            this.requestData.details = resource.data.details;
          }

          this.$store.dispatch(SHOW_SNACK, {
            message: "Normalize data stored successfuly.",
          });
          if (resource.data) {
            this.id = resource.data.id;
          }
        })
        .catch((response) => {})
        .finally(() => {
          this.isProcessing = false;
          this.$refs.requestData.resetValidation();
        });
    },
    back() {
      this.$router.push({ name: "normalize-data" });
    },
    change(items) {
      this.requestData.details = items;
      this.store();
    },
    onTypeChange() {
      if (!this.requestData.type_id) {
        return;
      }
      switch (this.requestData.type_id) {
        case 1:
          this.getMappings();
          break;

        default:
          break;
      }
    },
    getTitle() {
      return this.$route.params.id
        ? this.$t("NORMALIZE_DATA.EDIT")
        : this.$t("NORMALIZE_DATA.CREATE");
    },
    addRow() {
      if (!this.requestData.elements.where) {
        this.requestData.elements.where = [];
      }
      this.requestData.elements.where.push({
        type: "",
        column: "",
        operator: "",
        value: "",
      });
      this.tableKey += 1;
    },
    removeRow(i) {
      this.requestData.elements.where.splice(i, 1);
      this.tableKey += 1;
    },
  },
};
</script>

<style>
</style>